import React from 'react'
import {
  Seo,
  ContentWrapper,
  Section,
  LightSection,
  SectionTitle,
  Button,
  Wrapper,
  Marquee,
} from '@cgtalks/common/components'
import Layout from '../molecules/layout'
import AboutCg from '../molecules/guests'
import { MainBanner } from '../molecules/banner'
import BecomeImage from '../images/main/become.png'
import RecommandImage from '../images/main/recommend.png'
import BecomeTextImage from '../images/main/become-text.png'
import RecommandTextImage from '../images/main/recommend-text.png'
import {
  GuestCard,
  GuestCardWrapper,
  OnlineShareCardList,
  PastReview,
  OfflineShareSwiperWrapper,
  OfflineShareWrapper,
  OfflineShareTextContent,
  OfflineShareTextItemWrapper,
  OfflineShareTextItem,
  OfflineShareSwiperContent,
  GuestSwiperWrapper,
  GuestProfileWrapper,
} from './index.atom'
import { Link, graphql } from 'gatsby'
import { color } from '@cgtalks/common/theme'
import { IconTitle, SwiperSwitch, OnlineShareCard, GuestProfile } from '../molecules/main'

class Index extends React.Component {
  state = { offlineActiveIndex: 0 }
  render() {
    const {
      location,
      data,
      pageContext: { onlineSharingList, guestProfileList, offlineSharingList },
    } = this.props
    this.onlineSharingList = onlineSharingList
    this.guestProfileList = guestProfileList
    this.offlineSharingList = offlineSharingList
    console.log(this.offlineSharingList)
    return (
      <Layout location={location} friendLinks={data.friendLinks.nodes} isInMain>
        <Seo
          title='泛CG聚未来-一个属于CG人的自由分享、交流平台-Renderbus瑞云渲染'
          description='Renderbus瑞云渲染主办的“泛CG聚未来”，分线上直播、线下交流会，是一个属于CG人的自由分享、交流平台，这里有硬核技术、前沿工具、行业大牛经验等的免费分享。'
          keywords='泛CG, CG技术线上分享会,cg分享网,CG分享平台'
        />
        <ContentWrapper>
          <SwiperSwitch navBottom={40}>
            <MainBanner />
          </SwiperSwitch>
          <Section>
            <Wrapper>
              <SectionTitle text='线上分享'>泛CG实用技术线上分享</SectionTitle>
              <PastReview as={Link} to='/sharecg/'>
                往期回顾
              </PastReview>
              <OnlineShareCardList>
                {this.onlineSharingList.map((item, index) => (
                  <OnlineShareCard key={index} info={item.node} />
                ))}
              </OnlineShareCardList>
            </Wrapper>
          </Section>
          <LightSection>
            <Wrapper>
              <SectionTitle text='线下活动'>泛CG实用技术线下活动</SectionTitle>
              <OfflineShareWrapper>
                <OfflineShareSwiperWrapper>
                  <SwiperSwitch
                    style={{ width: '680px' }}
                    navBottom={30}
                    hiddenArrowIcon
                    onChange={(index) => {
                      this.setState({
                        offlineActiveIndex: index,
                      })
                    }}
                  >
                    {this.offlineSharingList ? (
                      this.offlineSharingList
                        .sort((a, b) => b.node.sort - a.node.sort)
                        .map((item, index) => {
                          const { video, image } = item.node
                          if (video) {
                            return (
                              <OfflineShareSwiperContent key={index}>
                                <video src={video[0]} controls />
                              </OfflineShareSwiperContent>
                            )
                          } else if (image) {
                            return (
                              <OfflineShareSwiperContent key={index}>
                                <img src={image[0]} alt='post' />
                              </OfflineShareSwiperContent>
                            )
                          }
                          return null
                        })
                    ) : (
                      <OfflineShareSwiperContent />
                    )}
                  </SwiperSwitch>
                </OfflineShareSwiperWrapper>
                <OfflineShareTextContent>
                  <div className='title'>
                    {this.offlineSharingList[this.state.offlineActiveIndex].node.title}
                  </div>
                  <div className='sub-title'>
                    {this.offlineSharingList[this.state.offlineActiveIndex].node.subTitle}
                  </div>
                  <OfflineShareTextItemWrapper>
                    <OfflineShareTextItem>
                      <span className='label'>活动时间：</span>
                      <span className='text'>
                        {this.offlineSharingList[this.state.offlineActiveIndex].node.specificTime}
                      </span>
                    </OfflineShareTextItem>
                    <OfflineShareTextItem>
                      <span className='label'>活动地点：</span>
                      <span className='text'>
                        {this.offlineSharingList[this.state.offlineActiveIndex].node.address}
                      </span>
                    </OfflineShareTextItem>
                    <OfflineShareTextItem className='fee-item'>
                      <span className='label'>活动费用：</span>
                      <span className='text fee'>
                        {this.offlineSharingList[this.state.offlineActiveIndex].node.cost === 0
                          ? '免费'
                          : this.offlineSharingList[this.state.offlineActiveIndex].node.cost}
                      </span>
                    </OfflineShareTextItem>
                  </OfflineShareTextItemWrapper>
                  <Button
                    as={Link}
                    to={`/cgactivity/${
                      this.offlineSharingList[this.state.offlineActiveIndex].node.slug
                    }.html`}
                  >
                    了解更多
                  </Button>
                </OfflineShareTextContent>
              </OfflineShareWrapper>
            </Wrapper>
          </LightSection>
          <Section>
            <Wrapper>
              <SectionTitle text='嘉宾阵容'>
                <IconTitle text='CG大咖 分享硬核干货' imgMbWidth='280' />
              </SectionTitle>
              <GuestSwiperWrapper>
                <Marquee center>
                  <GuestProfileWrapper>
                    {this.guestProfileList.map((item, i) => (
                      <GuestProfile key={i} info={item.node} />
                    ))}
                  </GuestProfileWrapper>
                </Marquee>
              </GuestSwiperWrapper>
            </Wrapper>
          </Section>
          <LightSection>
            <Wrapper>
              <SectionTitle text='嘉宾推荐'>
                <IconTitle text='荐CG大咖 赢好礼~' imgMbWidth='240' />
              </SectionTitle>
              <GuestCardWrapper>
                <GuestCard>
                  <img src={BecomeImage} alt='' />
                  <div>
                    <img src={BecomeTextImage} alt='' />
                    <Button as={Link} backgroundColor={color.primary} to='/become-a-speaker.html'>
                      立即加入
                    </Button>
                  </div>
                </GuestCard>
                <GuestCard>
                  <img src={RecommandImage} alt='' />
                  <div>
                    <img src={RecommandTextImage} alt='' />
                    <Button
                      as={Link}
                      backgroundColor={color.primary}
                      to='/guest-recommendation.html'
                    >
                      立即推荐
                    </Button>
                  </div>
                </GuestCard>
              </GuestCardWrapper>
            </Wrapper>
          </LightSection>
          <AboutCg isInMain={true} />
        </ContentWrapper>
      </Layout>
    )
  }
}

export default Index

export const query = graphql`
  query {
    friendLinks: allContentfulFriendLink(sort: { fields: rank, order: DESC }) {
      nodes {
        name
        url
      }
    }
  }
`
