import { Billboard } from '@cgtalks/common/components'
import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { BannerContainer, BannerWrapper, BannerTitle } from './atoms'
import CgQrcodeImage from '../../images/banner/main-banner/cg-qrcode.png'
import RenderbusQrcodeImage from '../../images/banner/main-banner/renderbus-qrcode.png'
import styled from 'styled-components'
import { Media, hideSMDown, hideSMUp, mbSize, typography } from '@cgtalks/common/theme'

const MainBannerTip = styled.div`
  position: absolute;
  top: 396px;
  font-size: 24px;
  color: #fff;
  line-height: 24px;
  ${Media.lessThan(Media.small)} {
    width: ${mbSize(598)};
    left: 50%;
    transform: translateX(-50%);
    top: ${mbSize(415)};
    font-size: ${mbSize(30)};
    font-weight: 400;
  }
`
const QrcodeWrapper = styled.div`
  position: absolute;
  right: 0;
  width: 200px;
  height: 240px;
  top: ${(p) => p.top};
  background: rgba(0, 0, 0, 0.5);
  text-align: center;
  .hide-at-mobile {
    ${hideSMDown};
  }
  .show-at-mobile {
    ${hideSMUp};
  }
  img {
    margin-top: ${(p) => p.paddingTop};
    margin-bottom: 20px;
  }
  p {
    margin: 0 0 10px 0;
    color: #fff;
    font-size: ${typography.textSmall};
    line-height: ${typography.textSmall};
  }
  ${Media.lessThan(Media.small)} {
    width: ${mbSize(200)};
    height: ${mbSize(240)};
    &.cg {
      top: ${mbSize(651)};
      left: ${mbSize(159)};
    }
    &.renderbus {
      top: ${mbSize(651)};
      left: ${mbSize(389)};
    }
    img {
      margin-top: ${mbSize(20)};
      margin-bottom: ${mbSize(5)};
      width: ${mbSize(140)};
    }
    p {
      font-size: ${mbSize(20)};
      line-height: ${mbSize(20)};
      margin: 0 0 ${mbSize(10)} 0;
    }
  }
`

const MainBanner = React.memo(function () {
  const data = useStaticQuery(graphql`
    query {
      banner: file(relativePath: { regex: "/banner/main-banner/bg.png/" }) {
        ...fluidImage
      }
      bannerMobile: file(relativePath: { regex: "/banner/main-banner/bg-mb.png/" }) {
        ...fluidImage
      }
    }
  `)
  return (
    <BannerContainer>
      <BannerWrapper>
        <BannerTitle>泛CG 聚未来</BannerTitle>
        <MainBannerTip>打造一个属于CG人的自由分享、交流平台</MainBannerTip>
        <QrcodeWrapper top='83px' paddingTop='30px' className='cg'>
          <img src={CgQrcodeImage} alt='cg qr-code' />
          <p>进群看直播聊CG</p>
        </QrcodeWrapper>
        <QrcodeWrapper top='344px' paddingTop='20px' className='renderbus'>
          <img src={RenderbusQrcodeImage} alt='renderbus qr-code' />
          <p className='hide-at-mobile'>关注瑞云官方微信服务号</p>
          <p className='show-at-mobile'>瑞云官方服务号</p>
          <p>直播福利早知道</p>
        </QrcodeWrapper>
      </BannerWrapper>
      <Billboard
        fluid={[
          data.banner.childImageSharp.fluid,
          { ...data.bannerMobile.childImageSharp.fluid, media: `(max-width: 780px)` },
        ]}
      />
    </BannerContainer>
  )
})

export default MainBanner
