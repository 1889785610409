import styled from 'styled-components'
import { Media, color, mbSize, typography } from '@cgtalks/common/theme'
import { Flex } from '@cgtalks/common/components'

export const GuestCard = styled.div`
  position: relative;
  div {
    position: absolute;
    top: 117px;
    left: 48px;
    display: flex;
    flex-direction: column;
  }
  a {
    color: black;
    width: 140px;
    height: 36px;
    line-height: 36px;
    padding: 0;
    margin-top: 35px;
    font-weight: unset;
    &:hover {
      background: linear-gradient(-90deg, #0fd2b7 0%, #25cf6b 100%);
    }
  }
  ${Media.lessThan(Media.small)} {
    width: ${mbSize(680)};
    height: ${mbSize(360)};
    > img {
      width: ${mbSize(680)};
      height: ${mbSize(360)};
    }
    div {
      top: ${mbSize(117)};
      left: ${mbSize(22)};
      img {
        width: ${mbSize(204)};
        height: ${mbSize(56)};
      }
    }
    a {
      width: ${mbSize(240)};
      height: ${mbSize(70)};
      font-weight: 500;
      font-size: ${mbSize(30)};
      line-height: ${mbSize(70)};
      margin-top: ${mbSize(35)};
    }
  }
`

export const GuestCardWrapper = styled(Flex)`
  justify-content: flex-start;
  margin-top: 42px;
  ${GuestCard}:first-child {
    margin: 0 40px 0 0;
  }
  ${Media.lessThan(Media.small)} {
    flex-direction: column;
    margin-top: ${mbSize(53)};
    padding-bottom: ${mbSize(94)};
    margin-bottom: ${mbSize(60)};
    ${GuestCard}:first-child {
      margin: 0 0 ${mbSize(32)} 0;
    }
  }
`

export const OnlineShareCardList = styled.div`
  display: flex;
  padding-top: 40px;
  > div {
    margin: 0 40px 0 0;
    &:last-child {
      margin: 0;
    }
  }
  ${Media.lessThan(Media.small)} {
    flex-direction: column;
    padding-top: ${mbSize(43)};
    padding-bottom: ${mbSize(156)};
    > div {
      margin: 0 0 ${mbSize(30)} 0;
      &:last-child {
        margin: 0;
      }
    }
  }
`

export const PastReview = styled.div`
  position: absolute;
  right: 16px;
  top: 0;
  color: #9b9baa;
  height: 24px;
  line-height: 24px;
  font-size: 14px;
  text-decoration: none;
  &:hover {
    color: ${color.primary};
  }
  &:after {
    content: '>';
    height: 24px;
    line-height: 24px;
    margin-left: 2px;
  }
  ${Media.lessThan(Media.small)} {
    font-size: ${mbSize(30)};
    height: ${mbSize(30)};
    line-height: ${mbSize(30)};
    top: unset;
    bottom: ${mbSize(75)};
    right: 50%;
    transform: translateX(50%);
  }
`

export const OfflineShareSwiperContent = styled.div`
  height: 360px;
  overflow: hidden;
  video,
  img {
    width: 100%;
    height: 100%;
  }
  ${Media.lessThan(Media.small)} {
    height: ${mbSize(360)};
  }
`

export const OfflineShareSwiperWrapper = styled.div`
  position: relative;
  width: 680px;
  height: 360px;
  background: #383844;
  border-radius: 10px;
`

export const OfflineShareTextItem = styled.div`
  display: flex;
  font-weight: bold;
  line-height: 36px;
  &.fee-item {
    align-items: flex-end;
  }
  .label {
    font-weight: 400;
  }
  .fee {
    font-size: 24px;
    line-height: 40px;
    color: ${color.primary};
  }
  ${Media.lessThan(Media.small)} {
    font-size: ${mbSize(30)};
    line-height: ${mbSize(36)};
    .label {
      min-width: max-content;
    }
    .fee {
      font-size: ${mbSize(30)};
      line-height: ${mbSize(36)};
    }
  }
`

export const OfflineShareTextItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 119px;
  justify-content: space-between;
`

export const OfflineShareTextContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #ffffff;
  .title {
    font-size: ${typography.h2};
    line-height: 45px;
    margin-bottom: 20px;
    font-weight: bold;
  }
  .sub-title {
    font-size: ${typography.text};
    line-height: ${typography.text};
    font-weight: 400;
  }
  ${OfflineShareTextItemWrapper} {
    margin: 32px 0 23px 0;
  }
  a {
    width: 160px;
    height: 36px;
    background: #00ffab;
    border-radius: 18px;
    padding: unset;
    color: #21252a;
    font-size: 16px;
    line-height: 36px;
    font-weight: unset;
    &:hover {
      background: linear-gradient(-90deg, #0fd2b7 0%, #25cf6b 100%);
    }
  }
  ${Media.lessThan(Media.small)} {
    padding: 0 0 ${mbSize(80)} 0;
    .title {
      font-size: ${mbSize(36)};
      margin-bottom: ${mbSize(30)};
      line-height: ${mbSize(60)};
    }
    .sub-title {
      font-size: ${mbSize(24)};
      line-height: ${mbSize(24)};
    }
    ${OfflineShareTextItemWrapper} {
      margin: ${mbSize(45)} 0 ${mbSize(49)} 0;
      height: ${mbSize(233)};
    }
    a {
      width: ${mbSize(300)};
      height: ${mbSize(70)};
      font-size: ${mbSize(30)};
      line-height: ${mbSize(70)};
    }
  }
`

export const OfflineShareWrapper = styled.div`
  position: relative;
  display: flex;
  ${OfflineShareSwiperWrapper}:first-child {
    margin: 0 61px 0 0;
  }
  padding-top: 37px;
  ${Media.lessThan(Media.small)} {
    flex-direction: column;
    ${OfflineShareSwiperWrapper} {
      width: ${mbSize(680)};
      height: ${mbSize(360)};
      &:first-child {
        margin: 0 0 ${mbSize(55)} 0;
      }
    }
  }
`

export const GuestSwiperContent = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 478px;
  > div {
    margin: 0 64px 0 0;
    &:last-child {
      margin: 0;
    }
  }
  ${Media.lessThan(Media.small)} {
    width: max-content;
    height: ${mbSize(570)};
    > div {
      margin: 0 ${mbSize(73)} 0 0;
      &:last-child {
        margin: 0;
      }
    }
  }
`

export const GuestProfileWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: max-content;
  min-width: 100%;
`

export const GuestSwiperWrapper = styled.div`
  width: 100%;
  height: 398px;
  ${Media.lessThan(Media.small)} {
    height: ${mbSize(570)};
  }
`
