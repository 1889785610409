import { H1 } from '@cgtalks/common/components'
import { Media, mbSize } from '@cgtalks/common/theme'
import styled from 'styled-components'

export const BannerContainer = styled.div`
  position: relative;
  height: 620px;
  display: flex;
  justify-content: center;
  ${Media.lessThan(Media.small)} {
    height: ${mbSize(1000)};
  }
`

export const BannerWrapper = styled.div`
  margin: 0 auto;
  width: 1400px;
  position: relative;
  ${Media.lessThan(Media.xxlarge)} {
    width: 1000px;
  }
  ${Media.lessThan(Media.small)} {
    width: 100vw;
  }
`

export const BannerTitle = styled(H1)`
  font-size: 100px;
  line-height: 1;
  font-weight: bold;
  color: #ffffff;
  width: max-content;
  height: max-content;
  position: absolute;
  top: 264px;
  ${Media.lessThan(Media.small)} {
    width: ${mbSize(598)};
    left: 50%;
    transform: translateX(-50%);
    top: ${mbSize(255)};
    font-size: ${mbSize(100)};
  }
`
